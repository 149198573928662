

function download(filename, text) {
  var element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}

import { MigrateService } from "@/services/MigrateService";
import alertS from "@/shared/AlertService/AlertService";
const migrate = new MigrateService();
export default {
  name: "Migration",
  data() {
    return {
      checkbox: false,
      showStatus: false,
      tracks: "",
      playlist: "",
      loading: {
        download: false,
        export: false,
        import: false,
        full: false,
      },
      origin: {
        username: "",
        password: "",
      },
      destiny: {
        username: "",
        password: "",
      },
    };
  },
  methods: {
    disableLoading(item:string) {
      const chk = this.loading[item];
      if(chk) return false;
      const anyTrue = Object.values(this.loading).find(el=> {
        return el == true;
      })
      if(anyTrue) return true;
      else return false;
    },
    async downloadTracks() {
      this.loading.download = true;
      const data = this.origin;
      if (!data.username) {
        return alertS.error("No Username / Email from Origin");
      }
      migrate
        .download(data)
        .then((res: any) => {
          if (res.data.error) {
            alertS.error(res.error);
          } else {
            const stringData = JSON.stringify(res.data, null, "\t");
            download(`backup-${data.username}.json`, stringData);
            console.log(res);
          }
        })
        .catch((res: any) => {
          alertS.error("File not found, you have to do the export first");
        })
        .finally(() => {
          this.loading.download = false;
        });
    },
    async getStatus(username:string,showAlert:boolean = true, oldAccountEmail = '') {
      const res = await migrate
        .status({username})
        .then((res: any) => {
          return res;
        });
      this.playlist = res.playlist;
      this.tracks = res.tracks; 
      if(res.finish == false) {
        setTimeout(()=> {
          this.getStatus(username);
        },500);
      } else {
        this.loading.import = false;
        if(this.checkbox) {
          if(oldAccountEmail) {
            this.$swal.fire({
                title: 'Your account was migrated and is ready for the upgrade',
                text: `Old email: ${oldAccountEmail}`,
                showDenyButton: true,
                showCancelButton: false,
                confirmButtonText: `I'm ready`,
                denyButtonText: `Don't upgrade`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.$router.push({ name: 'upgrade', params: { username: this.origin.username, password: this.origin.password } })
                } else if (result.isDenied) {
                  this.$swal.fire('Account will not be upgraded', '', 'info')
                }
              })
          } else {
            this.$swal.close();
            this.$router.push({ name: 'upgrade', params: { username: this.origin.username, password: this.origin.password } })
          }
        } else if(showAlert) {
          alertS.success(`<p>Import finished</p><p>Tracks: ${this.tracks}</p><p>Playlists: ${this.playlist}</p>`);
        }
         setTimeout(()=> {
          this.showStatus = false;
        },5000);
      }
    },
    async exportTracks() {
      const data = this.origin;
      if (!data.username) {
        return alertS.error("No Username / Email from Origin");
      }
      if (!data.password) {
        return alertS.error("No Password from Origin");
      }
      this.$swal.fire({
        icon: 'info',
        title: 'Export started!',
        text: 'Please be patient until we load all your tracks and playlist into our server!',
        allowOutsideClick: false
      })
      this.$swal.showLoading();
      this.loading.export = true;
      migrate
        .export(data)
        .then((res: any) => {
          if (res.error) {
            if(res.error == "Bad Account") res.error = "Incorrect username/email or password from Spotify";
            alertS.error(res.error);
          } else if (res.success) {
            alertS.success("Your playlist are safe in our servers, you can download it pressing download");
          }
        })
        .finally(() => {
          this.loading.export = false;
        });
    },
    async startGetStatus(username:string,showAlert:boolean = true, oldAccountEmail = '') {
      this.showStatus = true;
      this.getStatus(username,showAlert, oldAccountEmail);
    },
    async fullMigrate() {
      const Swal = this.$swal;
      if(!this.origin.username) {
        return alertS.error("Missing username from Origin")
      }
      if(!this.origin.password) {
        return alertS.error("Missing password from Origin")
      }
      Swal.fire({
          title: 'Are you sure?',
          text: 'Full transfer is under experimental mode. Will make a new account under the same email and migrate the playlist to the new account. Old account email address will be changed for a temporal encrypted email (Do not share with other people)',
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: `Save`,
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          const acc = {
            username: this.origin.username,
            password: this.origin.password
          }
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            Swal.fire({
              title: "Generating new account and migrating tracks / playlists",
              text: "Please be patient, this can take a moment",
              icon: "info"
            })
            Swal.showLoading();
            migrate
        .full_migrate(acc)
        .then((res: any) => {
          if (res.error) {
            if(res.error == "Bad Account") res.error = "Incorrect username/email or password from Spotify Destination";
            if(res.error == "Data is getting imported") {
              this.startGetStatus(acc.username, false, res.email);
            }
            alertS.error(res.error);
            this.loading.import = false;
          } else if (res.success) {
            this.startGetStatus(acc.username,false, res.email);
            alertS.success(`Import Started<br>Old Account Email: ${res.email} (Don't lose it) <br>Old Account Country: ${res.country}`);
          }
        })
        .catch(() => {
          this.loading.import = false;
        });
          } else if (result.isDenied) {
            Swal.fire('Changes are not saved', '', 'info')
          }
        })
    },
    async importTracks() {
      const data = this.origin;
      const dataOut = this.destiny;
      if (!data.username) {
        return alertS.error("No Username / Email from Origin");
      }
      if (!dataOut.username) {
        return alertS.error("No Username for Destination");
      }
      if (!dataOut.password) {
        return alertS.error("No Password for Destination");
      }
      this.$swal.fire({
        icon: 'info',
        title: 'Import started!',
        text: 'Please be patient until we load all your tracks into the destination account, we will report you once finished!',
        allowOutsideClick: false
      })
      this.$swal.showLoading();
      this.loading.import = true;
      migrate
        .import({origin: data.username, username: dataOut.username, password: dataOut.password})
        .then((res: any) => {
          if (res.error) {
            if(res.error == "Bad Account") res.error = "Incorrect username/email or password from Spotify Destination";
            if(res.error == "Data is getting imported") {
              this.startGetStatus(dataOut.username);
            }
            alertS.error(res.error);
            this.loading.import = false;
          } else if (res.success) {
            this.startGetStatus(dataOut.username);
            alertS.success("Import Started");
          }
        })
        .catch(() => {
          this.loading.import = false;
        });
    },
    clear() {
      this.origin = {
        username: "",
        password: "",
      };
      this.destiny = {
        username: "",
        password: "",
      };
    },
  },
};
