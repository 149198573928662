import axios from 'axios';
import {Account, AccountCountry} from '@/shared/interfaces';
import { BASE_URL } from '@/env';
declare var grecaptcha: any;

export class ServiceSpotify {
    private mainUrl:string = BASE_URL;
    private header = {
        "Public-Token": "eduairApp"
    }

    private axiosPost(url:string,account:any,cb:any) {
        axios({
            method: 'post', //you can set what request you want to be
            url: url,
            data: account,
            headers: this.header
        }).then(response => {
            if(!response.data) {
                alert("API ERROR");
            }
            cb(response.data);
        }).catch(e=> {
            cb({
                error: 'There is currently a proxy error, please try again later.'
            })
        })
    }

    public registerAccount(account:Account) {
        return new Promise(resolve=>{
            var model = "accounts/register-account";
            var url = this.mainUrl + model;
            this.axiosPost(url,account, function(result:any) {
                resolve(result);
            })
        })
    }

    public changeCountry(account:AccountCountry) {
        return new Promise(resolve=>{
            var model = "accounts/change-country";
            var url = this.mainUrl + model;
            this.axiosPost(url,account, function(result:any) {
                resolve(result);
            })
        })
    }

    public checkExists(account:Account) {
        return new Promise(resolve=>{
            var model = "accounts/check-email-exists";
            var url = this.mainUrl + model;
            this.axiosPost(url,account, function(result:any) {
                resolve(result);
            })
        })
    }
    
    public checkAccount(account:Account) {
        return new Promise(resolve=>{
            var model = "accounts/check-account";
            var url = this.mainUrl + model;
            this.axiosPost(url,account, function(result:any) {
                resolve(result);
            })
        })
    }

    public upgradeAccount(account:Account) {
        var self = this;
        return new Promise(resolve=>{
            var model = "accounts/upgrade-account";
            var url = this.mainUrl + model;
            grecaptcha.ready(function() {
                grecaptcha.execute('6LcTNcMUAAAAALfLcgD0y-A5e6t4vefcFNdeH5ED', {action: 'homepage'}).then(function(token) {
                    account['g-recaptcha-response'] = token;
                    self.axiosPost(url,account, function(result:string) {
                        resolve(result);
                    })
                });
            });
        })
    }
}
