window.$ = window.jQuery = require("jquery");

$( document ).ready(function() {

    function displayDropDown(element) {
        if(element.hasClass("off")) {
            var allDrops = $(".drop_down");
            if (allDrops.hasClass("on")) {
                allDrops.removeClass("on");
                allDrops.addClass("off");
            }
            element.removeClass("off");
            $(".drop_down").removeClass("on");
            element.addClass("on");
        } else {
            element.removeClass("on");
            element.addClass("off");
        }
    }
    
    var functionsArray = [];

    function activateClickDrop(name) {
        name = "#" + name;
        $(name).click(function(){
            var element = $($(this).data("target"));
            displayDropDown(element);
        });
    }

    var names = ["triggerCountry", "triggerCurrency", "streaming","porn","gaming","music","others"];

    names.forEach(function(name,index){
        console.log(name);
        activateClickDrop(name);
    });

});