
import GoTop from "@inotom/vue-go-top";
import Footer from "@/views/Footer.vue";

export default {
  beforeMount() {
    this.$vuetify.theme.dark = true;
  },
  name: "app",
  components: {
    GoTop,
    Navigation: () => import("@/components/Navigation.vue"),
  },
};
