import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import moment from "moment";
import "bootstrap";
import "daterangepicker";
import "jquery";
import VueTyperPlugin from "vue-typer";
import * as scriptJquery from "@/assets/js/style.js";
import vSelect from "vue-select";
import VModal from "vue-js-modal";
import VueLazyload from "vue-lazyload";
import AOS from "aos";
import "aos/dist/aos.css";
import VueMq from "vue-mq";
import VueTypedJs from "vue-typed-js";
import vuetify from "@/plugins/vuetify";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";

Vue.use(VueSweetalert2);

Vue.use(VueTypedJs);
Vue.use(VueMq, {
  breakpoints: {
    md: 768,
    lg: Infinity,
  },
  defaultBreakpoint: "sm",
});

export const serverBus = new Vue();

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@/assets/images/loading.svg"),
  loading: require("@/assets/images/loading.svg"),
  attempt: 1,
});

Vue.use(VModal, { dynamic: true, injectModalsContainer: true });

Vue.component("v-select", vSelect);
Vue.use(scriptJquery);
Vue.use(VueTyperPlugin);
Vue.config.productionTip = false;

import scroll from "vue-seamless-scroll";
//or you can set componentName default componentName is vue-seamless-scroll
Vue.use(scroll, { vueSeamless: "scroll-seamless" });

var eventHub: Vue;

export function geEvents() {
  if (!eventHub) {
    eventHub = new Vue();
  }
  return eventHub;
}

$(".shoppy-close");

// Importing the global css file test
import "@/styles/style.scss";
import "@/assets/fonts/font-awesome-4.7.0/css/font-awesome.min.css";
import "@/assets/aos/aos.css";
import "@mdi/font/css/materialdesignicons.css";

new Vue({
  created() {
    AOS.init({
      duration: 1200,
      once: true,
    });
  },
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
