
export default {
  methods: {
    get_upgrade() {
      const el = document.getElementById("upgrade_btn");
      if (el) {
        el.click();
      }
    },
  },
};
