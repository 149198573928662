import Vue from 'vue'
import VueRouter from 'vue-router'
import Migrate from '../views/Migrate.vue';
import Upgrade from '../views/Upgrade.vue';
import Login from '../views/Login.vue';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'upgrade',
    component: Upgrade
  },
  {
    path: '/migrate',
    name: 'migrate',
    component: Migrate
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },

]

const router = new VueRouter({
  base: '/',
  mode: 'history',
  // scrollBehavior (to, from, savedPosition) {
  //   return { x: 0, y: 0 }
  // },
  routes
})

export default router
