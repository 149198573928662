
import alertS from '@/shared/AlertService/AlertService'

export default {
    data() {
        return {
            token: "",
            loading: false,
        }
    },
    methods: {
        login(token) {
            if(token) {
                this.loading = true;
            } else {
                alertS.error("No token");
            }
        }
    }
}
