import axios from "axios";
import { Account, AccountCountry } from "@/shared/interfaces";
import { BASE_URL, MIGRATE_URL } from "@/env";
declare var grecaptcha: any;

export class MigrateService {
  private mainUrl: string = MIGRATE_URL;
  private header = {
    "Public-Token": "eduairApp",
  };

  private axiosPost(url: string, account: any, cb: any) {
    axios({
      method: "post", //you can set what request you want to be
      url: url,
      data: account,
      headers: this.header,
    }).then((response) => {
      if (!response.data) {
        alert("API ERROR");
      }
      cb(response.data);
    });
  }

  private axiosPostNoCb(url: string, account: any) {
    return axios({
      method: "post", //you can set what request you want to be
      url: url,
      data: account,
      headers: this.header,
    })
  }

  public export(account: { username: string; password: string }) {
    return new Promise((resolve) => {
      const model = "export";
      const url = this.mainUrl + model;
      this.axiosPost(url, account, function(result: any) {
        resolve(result);
      });
    });
  }
  public full_migrate(account: {
    username: string;
    password: string;
  }) {
    return new Promise((resolve) => {
      var model = "full_transfer";
      var url = this.mainUrl + model;
      this.axiosPost(url, account, function(result: any) {
        resolve(result);
      });
    });
  }
  public import(account: {
    username: string;
    password: string;
    origin: string;
  }) {
    return new Promise((resolve) => {
      var model = "import";
      var url = this.mainUrl + model;
      this.axiosPost(url, account, function(result: any) {
        resolve(result);
      });
    });
  }
  public status(account: { username: string }) {
    return new Promise((resolve) => {
      var model = "status";
      var url = this.mainUrl + model;
      this.axiosPost(url, account, function(result: any) {
        resolve(result);
      });
    });
  }
  public async download(account: { username: string }) {
      var model = "download";
      var url = this.mainUrl + model;
      return  this.axiosPostNoCb(url, account);
  }
}

const migrateService = new MigrateService();
export default migrateService;